import React, { useState, useEffect, } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TablePagination, useMediaQuery, List, ListItem, ListItemText, CircularProgress
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import CircleLoader from 'react-spinners/CircleLoader';
import ApiManager from '../../../utils/api';
import { formatDoubleToPaddedString, returnShareString } from '../../../utils/utils';
import { ThemeProvider } from '@emotion/react';
import { myDebugPrint } from '../../../utils/debug';
import { useSnackbar } from '../../../context/SnackbarContext';
import CounterpartyLinks from '../elements/CounterpartyLinks';

const CounterpartiesTable = ({ direction,
    projectId, currencyCode, recordsPerPage, filterCompany, selectedCompany,
    minDate, maxDate, filteredDateStart, filteredDateEnd,
    counterpartiesByFlows, totals, totalItems, loading,
    fetchCounterparties,
}) => {
    const pageSize = 10;
    const [page, setPage] = useState({
        'Inflows': 0,
        'Outflows': 0,
        'Sumflows': 0,
    });
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const [itemsPerPage, setItemsPerPage] = useState(20);

    useEffect(() => {
        setPage({
            'Inflows': 0,
            'Outflows': 0,
            'Sumflows': 0,
        })
    }, [projectId, currencyCode,
        filteredDateStart, filteredDateEnd,
    ]);


    const handleChangePage = async (event, newPage) => {
        const elementsFetched = counterpartiesByFlows.length;
        myDebugPrint(`handleChangePage: ${elementsFetched},  totalItems[direction]: ${totalItems[direction]}`)
        if ((newPage + 1) * recordsPerPage > elementsFetched && totalItems[direction] != elementsFetched) {
            // we need to call a fetcher!

            await fetchCounterparties({ fetchDirections: [direction,] })
        }
        setPage((prev) => {
            let newPages = { ...prev };
            newPages[direction] = newPage
            return (newPages)
        })



        // const totalPagesFetched = Math.ceil(counterpartiesByFlows[direction].length / recordsPerPage);
        // if (newPage >= totalPagesFetched && newPage * recordsPerPage < 20) {
        //     fetchCounterparties(Math.floor(newPage * recordsPerPage / pageSize));
        // }
        // setPage(newPage);
    };

    const getVisibleCounterparties = () => {
        const startIndex = page[direction] * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return counterpartiesByFlows.slice(startIndex, endIndex);
    };

    if (loading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircleLoader color="#8A4B9A" className='spinner' size={45} />
            </div>
        );
    }

    const theme = createTheme({
        components: {
            MuiTableRow: {
                variants: [
                    {
                        props: { variant: 'odd' },
                        style: {
                            backgroundColor: '#f9f9f9',
                        },
                    },
                    {
                        props: { variant: 'even' },
                        style: {
                            backgroundColor: 'transparent',
                        },
                    },
                ],
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        fontSize: 'small',
                        backgroundColor: null,
                    },
                    head: {
                        fontWeight: '800',
                        backgroundColor: 'lavender',
                    }
                },
            },
        },
    })

    return (
        <ThemeProvider theme={theme}>
            <Paper>
                {!isSmallScreen && (
                    <TableContainer>
                        <Table stripe='odd'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Контрагент</TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>Поступления</TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>Платежи</TableCell>
                                    <TableCell sx={{ textAlign: 'right' }}>Чистый&nbsp;поток</TableCell>
                                    {direction === 'Sumflows' &&
                                        <TableCell sx={{ textAlign: 'right' }}>Оборот</TableCell>
                                    }
                                    {totals &&
                                        ((direction === 'Inflows' && totals.TotalInflows && totals.TotalInflows > 0)
                                            || (direction === 'Outflows' && totals.TotalOutflows && totals.TotalOutflows > 0)
                                            || (direction === 'Sumflows' && totals.TotalSumflows && totals.TotalSumflows > 0)
                                        ) &&
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            Доля в {
                                                direction === 'Inflows' ? 'поступлениях'
                                                    : direction === 'Outflows' ? 'платежах'
                                                        : 'обороте'
                                            }
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getVisibleCounterparties().map((counterparty, index) => (
                                    (
                                        !selectedCompany
                                        || (!selectedCompany.TaxNumber && !selectedCompany.ShortName)
                                        || (
                                            selectedCompany.TaxNumber === counterparty.CounterpartyIdentifier
                                            && selectedCompany.ShortName === counterparty.Counterparty
                                        )
                                    ) &&
                                    <TableRow key={index} variant={index % 2 ? 'even' : 'odd'}>
                                        <TableCell>
                                            <span>
                                                {counterparty.Counterparty && counterparty.Counterparty != 'ИНН не указан' &&
                                                    <span>
                                                        {counterparty.Counterparty}
                                                    </span>
                                                }
                                            </span>
                                            <span>
                                                {counterparty.Counterparty && counterparty.Counterparty != 'ИНН не указан' &&
                                                    counterparty.Counterparty != counterparty.CounterpartyIdentifier &&
                                                    <span>
                                                        , ИНН&nbsp;
                                                    </span>
                                                }
                                                {counterparty.Counterparty != counterparty.CounterpartyIdentifier
                                                    ? counterparty.CounterpartyIdentifier : ''}
                                            </span>
                                            <span>
                                                {counterparty.Counterparty && counterparty.Counterparty == 'ИНН не указан' &&
                                                    <span>
                                                        , ИНН не указан
                                                    </span>
                                                }
                                            </span>
                                            <CounterpartyLinks
                                                filterCompany={filterCompany}
                                                entity={null}
                                                counterparty={counterparty}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            <span style={{
                                                textWrap: 'nowrap',
                                                color:
                                                    counterparty.Inflows > 0
                                                        ? 'green'
                                                        : 'grey'
                                            }}
                                            >
                                                <strong>
                                                    {counterparty.Inflows > 0 ? '+' : ''}
                                                </strong>
                                                &nbsp;
                                                {formatDoubleToPaddedString({ number: counterparty.Inflows, roundDigits: 0 })}
                                            </span>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            <span style={{
                                                textWrap: 'nowrap',
                                                color:
                                                    counterparty.Outflows > 0
                                                        ? 'red'
                                                        : 'grey'
                                            }}
                                            >
                                                <strong>
                                                    {counterparty.Outflows > 0 ? '-' : ''}
                                                </strong>
                                                &nbsp;
                                                {formatDoubleToPaddedString({ number: counterparty.Outflows, roundDigits: 0 })}
                                            </span>
                                        </TableCell>
                                        <TableCell sx={{ textAlign: 'right' }}>
                                            <span style={{
                                                textWrap: 'nowrap',
                                                color:
                                                    counterparty.Netflows > 0
                                                        ? 'green'
                                                        : counterparty.Netflows < 0
                                                            ? 'red'
                                                            : 'black'
                                            }}
                                            >
                                                <strong>
                                                    {counterparty.Netflows > 0
                                                        ? '+'
                                                        : counterparty.Netflows < 0
                                                            ? '-'
                                                            : '='}
                                                </strong>
                                                &nbsp;
                                                {formatDoubleToPaddedString({ number: Math.abs(counterparty.Netflows), roundDigits: 0 })}
                                            </span>
                                        </TableCell>
                                        {direction === 'Sumflows' &&
                                            <TableCell sx={{ textAlign: 'right' }}>
                                                <span style={{
                                                    textWrap: 'nowrap',
                                                    color: 'black'
                                                }}
                                                >
                                                    {formatDoubleToPaddedString({ number: counterparty.Sumflows, roundDigits: 0 })}
                                                </span>
                                            </TableCell>
                                        }
                                        {totals &&
                                            ((direction === 'Inflows' && totals.TotalInflows && totals.TotalInflows > 0)
                                                || (direction === 'Outflows' && totals.TotalOutflows && totals.TotalOutflows > 0)
                                                || (direction === 'Sumflows' && totals.TotalSumflows && totals.TotalSumflows > 0)
                                            ) &&
                                            <TableCell sx={{ textAlign: 'right' }}>
                                                {
                                                    direction === 'Inflows' ? returnShareString({ number: counterparty.Inflows, base: totals.TotalInflows })
                                                        : direction === 'Outflows' ? returnShareString({ number: counterparty.Outflows, base: totals.TotalOutflows })
                                                            : returnShareString({ number: counterparty.Sumflows, base: totals.TotalSumflows })
                                                }
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
                {isSmallScreen && (
                    <List>
                        {getVisibleCounterparties().map((counterparty, index) => (
                            <ListItem key={index} alignItems="flex-start">
                                <ListItemText
                                    primary={
                                        <>
                                            <strong>Контрагент: </strong>{counterparty.CounterpartyIdentifier}
                                            <br />
                                            <strong>Поступления: </strong>{formatDoubleToPaddedString({ number: counterparty.Inflows, roundDigits: 2 })}
                                            <br />
                                            <strong>Платежи: </strong>{formatDoubleToPaddedString({ number: counterparty.Outflows, roundDigits: 2 })}
                                            <br />
                                            <strong>Чистый поток: </strong>{formatDoubleToPaddedString({ number: counterparty.Netflows, roundDigits: 2 })}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
                {totalItems && totalItems[direction] &&
                    <TablePagination
                        component="div"
                        count={totalItems[direction]}
                        rowsPerPage={recordsPerPage}
                        page={!page ? 0 : ((page[direction] ?? 0))}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[recordsPerPage]}
                    />
                }
            </Paper>
        </ThemeProvider >
    );
};

export default CounterpartiesTable;
