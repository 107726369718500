import React from 'react';
import { CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Box, Tooltip } from '@mui/material';
import CashCardTemplate from './CashCardTemplate';
import { formatDoubleToPaddedString, parseAndFormatDate, parseDateFromNullableString } from '../../../utils/utils';
import { myDebugPrint } from '../../../utils/debug';
import { Check, HelpOutline, Warning } from '@mui/icons-material';

const validateINN = (inn) => {
    const cleanedINN = inn.replace(/\D/g, '');
    return cleanedINN.length === 10 || cleanedINN.length === 12 ? cleanedINN : 'нет данных';
};

const validateAccNmbr = (accNmbr) => {
    const cleanedAccNmbr = accNmbr.replace(/\D/g, '');
    return cleanedAccNmbr.length === 20 ? cleanedAccNmbr : 'нет данных';
};

const validateDate = (date) => {
    let res = 'нет данных'
    try {
        res = parseAndFormatDate({ dateString: date, sourceFormat: 'dd.MM.yyyy' })
    } catch { }
    return res;
};

const validateNumber = (value, roundDigits = 2) => {
    return formatDoubleToPaddedString({ number: value, roundDigits: roundDigits, onErrorReturn: 'нет данных' });
};

const formatErrorMessage = (value) => {
    if (value === null || value === undefined) {
        return 'нет данных'; // Handle null or undefined cases
    }
    if (String(value).toLowerCase() === 'данные сошлись') {
        return <span style={{ color: 'green', fontSize: 'x-large' }}>✓</span>
    }
    return <span style={{ color: 'red' }}>{value}</span>
}

const formatErrorStatement = (eIn, eOut) => {
    if ((eIn === null || eIn === undefined) || (eOut === null || eOut === undefined)) {
        return <span style={{ color: 'orange', fontSize: 'x-large' }}>⁉</span>
    }
    if (String(eIn).toLowerCase() === 'данные сошлись' && String(eOut).toLowerCase() === 'данные сошлись') {
        return <span style={{ color: 'green', fontSize: 'x-large' }}>✓</span>
    }
    return <span style={{ color: 'red', fontSize: 'x-large' }}>⚠</span>

}

const FileProcessingResults = ({ statementsSummary, errorsReport }) => {
    const allDataCorrect = () => {
        if (errorsReport && errorsReport.totalsCheck) {
            return errorsReport.totalsCheck.every(
                (error) => String(error.inflows).toLowerCase() === 'данные сошлись' && String(error.outflows).toLowerCase() === 'данные сошлись'
            );
        }
        return false;
    };

    const renderTableRows = () => {
        const rows = [];
        let i = -1;

        statementsSummary.forEach(statement => {
            const fileName = Object.keys(statement)[0];
            const fileData = statement[fileName];

            fileData.forEach((data, index) => {
                i = i + 1
                // myDebugPrint(`i:${i}, index: ${index}, data: ${JSON.stringify(data)}`);
                const errorData = errorsReport?.totalsCheck?.find(
                    (error) => error.filename === data.Filename && error.account === data.AccNmbr
                );

                rows.push(
                    <TableRow variant={(i % 2 !== 0) ? "oddCashAnalysis" : "evenCashAnalysis"} key={`${fileName}-${index}`}>
                        <TableCell variant="cashAnalysis">
                            {formatErrorStatement(errorData.inflows, errorData.outflows)}
                        </TableCell>
                        <TableCell variant="cashAnalysis">{data.Filename}</TableCell>
                        <TableCell variant="cashAnalysis">{validateINN(data.INN)}</TableCell>
                        <TableCell variant="cashAnalysis">{validateAccNmbr(data.AccNmbr)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>
                            {`${validateDate(data.DateStart)} - ${validateDate(data.DateEnd)}`}
                        </TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.WasOnStart)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.Outflow)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.Inflow)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.WasOnEnd)}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.transactionsNumberOutflows, 0)} шт.</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{validateNumber(data.transactionsNumberInflows, 0)} шт.</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{errorData ? formatErrorMessage(errorData.outflows) : 'нет данных'}</TableCell>
                        <TableCell variant="cashAnalysis" sx={{ textAlign: 'center' }}>{errorData ? formatErrorMessage(errorData.inflows) : 'нет данных'}</TableCell>
                    </TableRow>
                );
            });
        });

        return rows;
    };

    const tableContent = (
        <CardContent>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}></TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}>Файл</TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}>
                                <Tooltip title='В выписках может быть не указан ИНН или указан в машинно-нечитаемом виде. Даже в таких случаях мы определяем ИНН с точностью 98,7%. Если что-то не так, напишите на info@brpadvice.ru'>
                                    <span>ИНН <HelpOutline sx={{ color: 'blue', fontSize: 'medium' }} /></span>
                                </Tooltip>
                            </TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2}>Счет</TableCell>
                            <TableCell variant="cashAnalysisHead" rowSpan={2} sx={{ textAlign: 'center' }}>Период</TableCell>
                            <TableCell variant="cashAnalysisHead" colSpan={4} sx={{ textAlign: 'center' }}>Данные по итогам</TableCell>
                            <TableCell variant="cashAnalysisHead" colSpan={2} sx={{ textAlign: 'center' }}>Операций в выписке</TableCell>
                            <TableCell variant="cashAnalysisHead" colSpan={2} sx={{ textAlign: 'center' }}>Сверка по операциям</TableCell>
                        </TableRow>
                        <TableRow>
                            {/* These cells correspond to the sub-headers under "Данные по итогам" */}
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Остаток на начало (по итогам)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Расходы (по итогам)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Приходы (по итогам)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Остаток на конец (по итогам)</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Платежи</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Поступления</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Платежи</TableCell>
                            <TableCell variant="cashAnalysisHead" sx={{ textAlign: 'center' }}>Поступления</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {renderTableRows()}
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>
    );

    const correct = allDataCorrect()
    return (
        <CashCardTemplate
            title={<Box>
                <span>{(
                    correct
                        ? <span style={{ color: 'green', fontSize: 'larger' }}>✓</span>
                        : <span style={{ color: 'red', fontSize: 'larger' }}>⚠</span>
                )}
                    &nbsp;Результаты обработки файлов
                </span>
            </Box>
            }
            subheader="Сверка оборотов и операций по каждой выписке"
            cardcontent={tableContent}
            initalFolded={correct}
        />
    );
};

export default FileProcessingResults;
