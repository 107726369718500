import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, Grid, Tabs, Tab, useMediaQuery, Divider } from '@mui/material';
import { inflowsColor, outflowsColor } from '../../../utils/vars';
import { formatMonth, shortenCashNumbers } from '../../../utils/utils';
import ApiManager from '../../../utils/api';
import Slicer from '../../common/Slicer';
import ToggleIconButton from '../../common/ToggleIconButton';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import UnfoldLess from '@mui/icons-material/UnfoldLess';
import UnfoldMore from '@mui/icons-material/UnfoldMore';
import PaymentsTable from './PaymentsTable';
import { myDebugPrint } from '../../../utils/debug';
import CashCardTemplate from './CashCardTemplate';
import CounterpartiesTable from './CounterpartiesTable';
import DateFilter from '../elements/DateFilter';
import { useSnackbar } from '../../../context/SnackbarContext';

const CounterpartiesTableWrapper = ({ projectId, currencyCode, updateCashProjectPayment,
    onFilterDates, filteringInProgress,
    minDate, maxDate, filteredDateStart, filteredDateEnd,
    filterCompany, selectedCompany,
}) => {
    const showSnackbar = useSnackbar();
    const [counterpartiesByFlows, setCounterpartiesByflows] = useState(
        {
            'Inflows': [],
            'Outflows': [],
            'Sumflows': []
        }
    );
    const [isFetching, setIsFetching] = useState(
        {
            'Inflows': false,
            'Outflows': false,
            'Sumflows': false,
        }
    )
    const [pageSize, setPageSize] = useState(60);
    const [totals, setTotals] = useState(null);
    const [totalItems, setTotalItems] = useState(
        {
            'Inflows': false,
            'Outflows': false,
            'Sumflows': false,
        }
    );
    const [activeTab, setActiveTab] = useState('Inflows')

    const fetchCounterpartiesByFlows = async ({ fetchDirections = ['Inflows', 'Outflows', 'Sumflows'], clearAll = false } = {}) => {
        myDebugPrint(`ok, fetchCounterpartiesByFlows 1`)
        if (clearAll) {
            setCounterpartiesByflows({
                'Inflows': [],
                'Outflows': [],
                'Sumflows': [],
            })
            setTotalItems({
                'Inflows': false,
                'Outflows': false,
                'Sumflows': false,
            })
            setTotals(null);
        }
        myDebugPrint(`ok, fetchCounterpartiesByFlows 2`)
        const fetchByDirectionAndPage = async ({ fetchDirection = 'Inflows', clear = false }) => {
            myDebugPrint(`ok, fetchByDirectionAndPage 3`)
            if (totalItems && totalItems[fetchDirection]
                && counterpartiesByFlows && counterpartiesByFlows[fetchDirection]
                && totalItems[fetchDirection] === counterpartiesByFlows[fetchDirection].length
            ) {
                showSnackbar('Достигнут конец таблицы', 'warning');
                return;
            }
            setIsFetching((prev) => {
                let newIsFetching = { ...prev }
                newIsFetching[fetchDirection] = true
                return newIsFetching
            })
            myDebugPrint(`ok, fetchByDirectionAndPage 4`)
            try {
                const response = await ApiManager.cashGetCounterparties({
                    projectId: projectId,
                    direction: fetchDirection,
                    currencyCode: currencyCode,
                    offset: (clear ? 0 : (
                        (counterpartiesByFlows && counterpartiesByFlows[fetchDirection]) ? counterpartiesByFlows[fetchDirection].length : 0
                    )),
                    pageSize: pageSize,
                    needTotals: clear ? true : (!totals || totals['TotalSumflows'] === false || totals['TotalSumflows'] === null),
                    dateFrom: (filteredDateStart && minDate && (filteredDateStart != minDate)) ? filteredDateStart : null,
                    dateTo: (filteredDateEnd && maxDate && (filteredDateEnd != maxDate)) ? filteredDateEnd : null,
                });
                myDebugPrint(`ok, fetchByDirectionAndPage 5`)
                if (!response || !response.records) {
                    myDebugPrint(`ok, fetchByDirectionAndPage 5-6`)
                    myDebugPrint(`Error fetching counterparties`);
                    showSnackbar(`Невозможно загрузить контрагентов. Если проблема сохраняется, напишите на info @brpadvice.ru`);
                } else if (response && response.records && response.records.length === 0) {
                    showSnackbar((
                        counterpartiesByFlows
                        && counterpartiesByFlows[fetchDirection]
                        && counterpartiesByFlows[fetchDirection].length === 0
                    ) ? 'Контрагенты не найдены' : 'Достигнут конец таблицы', 'warning');
                } else {
                    setCounterpartiesByflows((prev) => {
                        // Concat new records
                        const combinedCounterparties = (clear || !prev || !prev[fetchDirection]) ? [...response.records] : [...prev[fetchDirection], ...response.records];
                        // Remove duplicates based on CounterpartyIdentifier
                        const uniqueCounterparties = Array.from(new Set(combinedCounterparties.map(counterparty => counterparty.CounterpartyIdentifier)))
                            .map(id => combinedCounterparties.find(counterparty => counterparty.CounterpartyIdentifier === id));
                        const newcounterparties = JSON.parse(JSON.stringify(prev));
                        newcounterparties[fetchDirection] = uniqueCounterparties;
                        return newcounterparties;
                    });
                    if (response && response.totals) {
                        setTotals(response.totals);
                    }
                    if (response && response.totalItems) {
                        setTotalItems(response.totalItems);
                    }
                }
            } catch (error) {
                myDebugPrint(`ok, fetchByDirectionAndPage 6`)
                myDebugPrint(`Error fetching counterparties on project: ${projectId}, error: ${JSON.stringify(error)} `);
                showSnackbar(`Невозможно загрузить контрагентов. Если проблема сохраняется, напишите на info @brpadvice.ru`);
            } finally {
                myDebugPrint(`ok, fetchByDirectionAndPage 7`)
                setIsFetching((prev) => {
                    let newIsFetching = { ...prev }
                    newIsFetching[fetchDirection] = false
                    return newIsFetching
                })
            }
        }
        fetchDirections.forEach((fetchDirection) => {
            fetchByDirectionAndPage({ fetchDirection: fetchDirection, clear: clearAll })
        })
    }

    const handleTabChange = (event, newValue) => {
        setActiveTab(['Inflows', 'Outflows', 'Sumflows'][newValue]);
    };
    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    function actveTabNumber() {
        if (activeTab === 'Inflows') return 0;
        if (activeTab === 'Outflows') return 1;
        if (activeTab === 'Sumflows') return 2;
        return 0;
    }

    useEffect(() => {
        fetchCounterpartiesByFlows({ clearAll: true })
    }, [projectId, currencyCode,
        filteredDateStart, filteredDateEnd,
    ]);


    return (
        <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '24px' }}>
                <Tabs value={actveTabNumber()} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Поступления" {...a11yProps(0)} />
                    <Tab label="Платежи" {...a11yProps(1)} />
                    <Tab label="Встречный оборот" {...a11yProps(2)} />
                </Tabs>
            </Box>
            <CounterpartiesTable
                key={projectId + '~' + 'inflows'}
                direction={activeTab ? activeTab : 'Inflows'}
                projectId={projectId}
                currencyCode={currencyCode}
                recordsPerPage={20}
                filterCompany={filterCompany}
                selectedCompany={selectedCompany}
                minDate={minDate}
                maxDate={maxDate}
                filteredDateStart={filteredDateStart}
                filteredDateEnd={filteredDateEnd}
                filteringInProgress={filteringInProgress}
                counterpartiesByFlows={counterpartiesByFlows ? counterpartiesByFlows[activeTab ? activeTab : 'Inflows'] : []}
                totals={totals}
                totalItems={totalItems}
                loading={isFetching ? isFetching[activeTab ? activeTab : 'Inflows'] : null}
                fetchCounterparties={fetchCounterpartiesByFlows}
            />
        </Box>
    );
}


export default CounterpartiesTableWrapper;