import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams, useNavigate, createSearchParams } from "react-router-dom";
import DashboardSkeleton from '../components/dashboard/DashboardSkeleton';
import { myDebugPrint, formatDoubleToPaddedString } from '../utils/debug';
import ApiManager from '../utils/api';
import { useSnackbar } from '../context/SnackbarContext';
import CorpCommonSection from '../components/dashboard/CorpCommonSection';
import Entity from '../classes/Entity';
import { Typography } from '@mui/material';
import { pCommon, pFinrep, pKad, pLicense, pSME, pSubdivisions, pTaxes, pTrademark } from '../classes/CorpDrawerTaps';
import TaxesSection from '../components/dashboard/TaxesSection';
import KadSection from '../components/dashboard/KadSection';
import LicenseSection from '../components/dashboard/LicenseSection';
import TrademarkSection from '../components/dashboard/TrademarkSection';
import SMESection from '../components/dashboard/SMESection';
import SubdivisionSection from '../components/dashboard/SubdivisionSection';
import FinRepSection from '../components/dashboard/FinRepSection';

const defaultIsFetchingStatus = 'Ищем в свежих базах ФНС, арбитражных судов, корпорациии МСП и в других источниках... Скоро здесь появятся нужные данные.';
const defaultFullAccessSuccess = 'Полный доступ к историческим данным открыт!';

const CorpDetailed = () => {
    const [searchParams] = useSearchParams();
    const [entity, setEntity] = useState(null);
    const [limitsRest, setLimitsRest] = useState(null);
    const [fetchingStatus, setFetchingStatus] = useState(defaultIsFetchingStatus);
    const { companyID } = useParams();
    const [section, setSection] = useState(searchParams.get('s') ?? pCommon);
    const showSnackbar = useSnackbar();
    const navigate = useNavigate();
    const [fullData, setFullData] = useState(false)

    const handleOpenCompanyFull = async ({ }) => {
        setEntity(null);
        setLimitsRest(null);
        setFullData(false)
        setFetchingStatus(defaultIsFetchingStatus)
        try {
            const response = await ApiManager.detailedCorp({ ogrn: companyID, full: true, })
            const fetchedEntity = response['entity'];
            const ent = Entity.fromJson(fetchedEntity);
            setEntity(ent);


            setLimitsRest(response['limitsRest']);

            if (response['error']) {
                handleError(response['error']);
            }

            if (response['entity']['fullAccessError'] || response['error']) {
                handleError({ errorMessage: response['entity']['fullAccessError'] || response['error'] });
            } else {
                showSnackbar(defaultFullAccessSuccess, 'success');
            }
            setFullData(true)
        } catch (e) {
            myDebugPrint(`handleSubmit error: ${e}, ${JSON.stringify(e)}`);
            setFetchingStatus('Поиск отменен. Чтобы открыть нужные данные, обновите страницу.')
            handleError({ errorMessage: 'Ошибка при выполнении поиска. Обновите страницу через минуту. Связь с поддержкой info@brpadvice.ru' });
        } finally {
            setFetchingStatus(null);
        }
    }

    const handleSearch = async (query) => {
        if (!query || query.length < 2) { return false; }

        navigate({
            pathname: "/search",
            search: createSearchParams({
                q: query
            }).toString(),
        });
    };

    const handleError = ({ errorMessage }) => {
        showSnackbar(errorMessage, 'error');
    };

    const handleFetch = async (signal) => {
        setEntity(null);
        setLimitsRest(null);
        setFullData(false)
        if (!companyID || companyID.length < 10) {
            setFetchingStatus('По такому ОГРН найти компанию или ИП не удалось. Если вы знаете, что она (он) существует, напишите на info@rpadvice.ru')
            return false;
        }
        try {
            const response = await ApiManager.detailedCorp({ ogrn: companyID });
            if (!signal || !signal.aborted) {
                const fetchedEntity = response['entity'];

                const ent = Entity.fromJson(fetchedEntity);
                setEntity(ent);

                setLimitsRest(response['limitsRest']);

                if (response['error']) {
                    handleError(response['error']);
                }
            }
        } catch (e) {
            if (!signal || !signal.aborted) {
                myDebugPrint(`handleSubmit error: ${e}, ${JSON.stringify(e)}`);
                setFetchingStatus('Поиск отменен. Чтобы открыть нужные данные, обновите страницу.')
                handleError({ errorMessage: 'Ошибка при выполнении поиска. Обновите страницу через минуту. Связь с поддержкой info@brpadvice.ru' });
            }
        } finally {
            if (!signal || !signal.aborted) {
                setFetchingStatus(null);
            }
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        handleFetch(controller.signal);

        return () => {
            controller.abort();
        };
    }, [companyID]);

    useEffect(() => {
        setSection(searchParams.get('s') ?? pCommon);
    }, [searchParams, companyID]);

    const handleSectionChange = (newSection) => {
        if (section === newSection) return;
        if (newSection === pCommon) {
            setSection(newSection);
            navigate(`/${companyID}`);
            return;
        }
        setSection(newSection);
        navigate({
            pathname: `/${companyID}`,
            search: createSearchParams({ s: newSection }).toString(),
        });
    };

    function buildProperSection() {
        let widget = <CorpCommonSection
            limitsRest={limitsRest}
            entity={entity}
            handleSearch={handleSearch}
            activeSection={section}
        />;
        switch (section) {
            case pCommon:
                widget = <CorpCommonSection
                    limitsRest={limitsRest}
                    entity={entity}
                    handleSearch={handleSearch}
                    activeSection={section}
                    callbackOpenCompanyFull={handleOpenCompanyFull}
                />;
                break;
            case pTaxes:
                widget = <TaxesSection
                    entity={entity}
                />;
                break;
            case pKad:
                widget = <KadSection
                    entity={entity}
                />
                break;
            case pLicense:
                widget = <LicenseSection
                    entity={entity}
                />
                break;
            case pTrademark:
                widget = <TrademarkSection
                    entity={entity}
                />
                break;
            case pSME:
                widget = <SMESection
                    entity={entity}
                />
                break;
            case pSubdivisions:
                widget = <SubdivisionSection
                    entity={entity}
                />
                break;
            case pFinrep:
                widget = <FinRepSection
                    entity={entity}
                />
                break;

        }
        return widget;
    };

    return (
        <DashboardSkeleton
            initialQuery={''}
            isSearching={false}
            isCorp={true}
            companyName={(entity && entity.name) ? entity.name : ''}
            activeSection={section}
            onSectionChange={fullData ? handleSectionChange : null}
        >
            {entity ?
                buildProperSection() : (
                    <Typography variant="body2">
                        {fetchingStatus}
                    </Typography>
                )}
        </DashboardSkeleton>
    );
};

export default CorpDetailed;
